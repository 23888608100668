import { defaultJuniper } from './default-juniper-de';
import { sharedProdEuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultJuniper,
  ...sharedProdEuEnv,
  environment: 'production',
  apiUrl: 'https://api.admin.eu.eucalyptus.vc',
  restApiUrl: 'https://api.admin.de.myjuniper.com',
  assetsUrl:
    'https://storage.googleapis.com/euc-services-prod-juniper-de-assets',
  logoUrl: 'https://assets.myjuniper.co.uk/juniper-logo.svg', // copied from UK
  shopifyHostname: undefined,
  userAppUrl: 'https://app.de.myjuniper.com',
  doctorsAppUrl: 'https://prescribers.de.myjuniper.com',
  googleOAuthClientId:
    '966685249037-gin9l5mhlahpk6eud426k1fu32ksnvm5.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.de.myjuniper.com',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee5',
  planIntervals: ['Weekly'],
};
